@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    scrollbar-width: thin;
    scrollbar-color: grey transparent;
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: grey;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

/* hubspot form */
#hubSpotForm label {
  color: #1b1b1b;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.hs_submit .hs-button.primary.large {
  width: 100%;
  border-radius: 4px;
  background: #4aa0a8;
  color: #ffffff;
  border: none;
}

.hs-form-field {
  padding-bottom: 12px;
}

.hs-richtext h1 {
  color: #1b1b1b;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  margin-top: 0;
}

.hs-richtext p {
  color: #7a7a7a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
  margin-top: 0;
}

.hs-form-field {
  padding-bottom: 12px;
}

.hs-input {
  border-radius: 4px;
  border: 1px solid #bcbcbc;
  background: #fff;
  width: 100%;
  padding: 0.5rem 1rem;
  transition: all 0.3s;
}

.hs_submit .hs-button.primary.large {
  width: 30%;
  display: block;
  margin-left: auto;
  border-radius: 4px;
  background: #68b2bd;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  font-weight: 400;
  cursor: pointer;
}

.hs_submit .hs-button.primary.large:hover {
  background: #487c84;
}

.hs-error-msg,
.hs-error-msgs li label {
  font-weight: 400 !important;
  color: #f81414 !important;
}

.hs-form a {
  color: #68b2bd !important;
  font-weight: 500;
}

.hs-form a:hover {
  color: #487c84 !important;
}

.hs-form em {
  font-size: 12px;
  color: #7a7a7a;
  font-weight: 400;
}

.hs-form-required {
  color: #f81414;
  margin-left: 2px;
}

.hs-form .is-placeholder {
  color: #a0a2ab;
}

.hs-form select {
  padding-right: 24px;
}

.hs-fieldtype-file {
  padding-bottom: 0 !important;
}

.hs-fieldtype-intl-phone.hs-input {
  display: flex;
}

.hs-fieldtype-intl-phone.hs-input select {
  flex-basis: 40%;
  margin-right: 5px;
}

.hs-fieldtype-intl-phone.hs-input input {
  flex-basis: 60%;
}

#genesys-messenger {
  position: relative;
  z-index: 100;
}

.submitted-message p {
  margin-bottom: 6px !important;
}

.submitted-message p span {
  font-size: 0.875rem !important;
  line-height: 1rem !important;
  font-family: 'Roboto', sans-serif !important;
  color: #5f5f5f !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
